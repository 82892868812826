/*
Copyright (C) CELSOS. All rights reserved.
! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 10, 2018
*/
@font-face {
    font-family: 'silkaregular';
    src: url('/public/silka-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}