/* Copyright (C) CELSOS. All rights reserved. */
.image-carousel-container {
  max-width: 90%; /* Ancho máximo del contenedor */
  margin: 0 auto; /* Centrar horizontalmente */
  padding: 10px; /* Espaciado interno */
  height: 100%; /* Altura fija para el contenedor del carrusel */
  display: flex;
  justify-content: center;
  align-items: center; /* Centrar verticalmente */
}

.image-carousel-container .carousel .thumbs-wrapper .carousel-thumb.selected {
  border: 3px solid #656cf6; /* Borde azul para la miniatura seleccionada */
}

.image-carousel-container .carousel .thumbs-wrapper .thumbs .thumb {
  border: none; /* Elimina cualquier borde de las miniaturas */
  box-shadow: none; /* Elimina cualquier sombra de las miniaturas */
}

.carousel-slide {
  height: 100%;
}

.carousel-slide img {
  max-height: 500px; /* Limita la altura de la imagen principal */
  width: auto;
  object-fit: contain; /* Mantiene la proporción de la imagen */
  margin: 0 auto; /* Centra la imagen horizontalmente */
}

@keyframes scanner {
  0% {
    transform: translateX(100%) scaleX(.5);
  }
  100% {
    transform: translateX(-50%) scaleX(0.5);
  }
}

.scan-effect {
  position: relative;
}

.scan-effect::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(45, 5, 192, 0.301);
  background: linear-gradient(90deg, transparent, rgba(29, 63, 255, 0.493), transparent);
  animation: scanner 2s forwards infinite;
  pointer-events: none; /* Asegura que el efecto no sea interactivo */
  display: none;
}

.scan-effect.active::before {
  display: block;
}
