/* Copyright (C) CELSOS. All rights reserved. */
.App {
  text-align: center;
  min-height: 100vh;
  padding: 10px;
  background-image: url('../public/background.png');
  background-size: cover;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.darken-hover {
  color: rgb(255, 255, 255);
  transition: color 0.3s;
}

.modal-box {  
  position: absolute;  
  top: 50%;  
  left: 50%;  
  transform: translate(-50%, -50%);  
  width: 50%;  
  background-color: #1b1b49; /* Reemplaza con el color de fondo que necesitas */ 
  box-shadow: 0 4px 24px rgba(77, 77, 206, 0.5); /* Ajusta la sombra según tus necesidades */  
  padding: 20px; /* Esto corresponde a 4 en el sistema de espaciado de Material-UI */  
  outline: 0;
  border-radius: 5px;
}  

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.darken-hover:hover {
  color: rgb(96, 96, 96); /* Adjust this to your desired darker color */
}

.images-container {
  display: flex;
  flex-direction: row; /* Coloca los elementos en fila */
  align-items: flex-start; /* Alinea los elementos arriba */
  padding: 20px;
  height: 500px; /* Altura fija para el contenedor de imágenes */
}

.button-column {
  width: 30%;
  display: flex;
  flex-direction: column; /* Coloca los botones uno encima de otro */
  justify-content: start; /* Alinea los botones arriba */
  background-color: #0000076e;
}

.vertical-slide{
  animation: slide-in 2s ease forwards;
}

.spawn-analyze{
  opacity: 0;
  animation: fade-in 2s ease forwards;
  animation-delay: 0.5s;
}

.spawn-save{
  opacity: 0;
  animation: fade-in 2s ease forwards;
  animation-delay: 1s;
}

.spawn-carrusel {
  opacity: 0;
  animation: fade-in 2s ease forwards;
  animation-delay: 1.5s;
  width:100%;
}

@keyframes slide-in {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.vertical-slide-up{
  animation: slide-in-up 2s ease forwards;
}

@keyframes slide-in-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.shrink-animation {
  animation: slide-and-shrink 3s ease forwards;
}

@keyframes slide-and-shrink {
  0% {
    transform: translateX(0) scaleX(1);
  }
  100% {
    transform: translateX(-40%) scaleX(0.15);
  }
}

