/* Copyright (C) CELSOS. All rights reserved.*/
@keyframes fadeIn {
    from {
      opacity: 0;
      color: rgba(255, 255, 255, 0); /* Color inicial */
    }
    to {
      opacity: 1;
      color: #656aff; /* Color final */
    }
  }
  
  .fade-in {
    opacity: 0;
    color: rgba(255, 255, 255, 0); /* Color inicial */
    animation: fadeIn 1s ease-in forwards;
  }